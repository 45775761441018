import React from "react"
import Facebook from "../../../images/Facebook.svg"
import LinkedIn from "../../../images/LinkedIn.svg"
import Twitter from "../../../images/Twitter.svg"

const ShareSocial = (props) => {
  return (
    <>
      <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${props.currentUrl}`} target="_blank" rel="noopener noreferrer">
        <img src={LinkedIn} alt="LinkedIn" />
      </a>
      <a href={`https://www.facebook.com/sharer/sharer.php?u=${props.currentUrl}`} target="_blank" rel="noopener noreferrer">
        <img src={Facebook} alt="Facebook" />
      </a>
      <a href={`https://twitter.com/intent/tweet/?url=${props.currentUrl}`} target="_blank" rel="noopener noreferrer">
        <img src={Twitter} alt="Twitter" />
      </a>
    </>
  )
}

export default ShareSocial