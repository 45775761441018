import React from "react"

import Layout from "../components/layouts/Layout/layout"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import remarkGfm from "remark-gfm"
import rehypeRaw from "rehype-raw"

import Imprimante from "../images/imprimante.svg"

import componentFactory from "../utils/componentFactory"

import TitreBlock from "../components/blocks/TitreBlock/TitreBlock"
import "./article-template-markdown.scss"
import "./article-template.scss"
import Seo from "../components/seo"
import ShareSocial from "../components/blocks/ShareSocial/ShareSocial"
import moment from "moment/moment"
import { Link } from "gatsby"

function printPage() {
  if (typeof window !== "undefined") {
    window.print()
    return false
  }
}

const ArticleTemplate = ({ pageContext }) => {
  const item = pageContext.item.attributes
  const page = pageContext.page.attributes
  const currentUrl = encodeURIComponent(
    process.env.GATSBY_SITE_BASEURL + pageContext.item.url
  )

  const components = page.contenu.map(i => componentFactory(i, pageContext))

  const isValidUrl = urlString => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ) // validate fragment locator
    return !!urlPattern.test(urlString)
  }

  function LinkRenderer(props) {
    return isValidUrl(props.href) === true ? (
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    ) : (
      <Link to={props.href}>{props.children}</Link>
    )
  }

  return (
    <>
      <Seo
        description={page.metaDescription}
        title={item.titre}
        lang="fr"
      ></Seo>
      <Layout chemin={[{titre:'Pour les aidants'},{titre:'Articles'}, {titre:item.titre}]}>
        <article className={" article_template"}>
          <div
            style={{ backgroundColor: item.categorie.data.attributes.couleur }}
            className="titre-background"
          >
            <TitreBlock
              title={item.titre}
              titleColor={item.couleurTitre}
              baseline={item.resume}
              alignment={"center"}
              level="h1"
            />
          </div>
          {/* <div className="Article-markdown__image-container">
            <img
              className="Article-markdown__image"
              src={item.vignetteGrande.data?.attributes.url}
              alt={item.vignetteGrande.data?.attributes.alternativeText}
            />
          </div> */}
          <div className="Article-markdown__Share container">
            <div className="Article-markdown__Share__Social">
              <p>Partager sur</p>
              <ShareSocial currentUrl={currentUrl} />
            </div>
            <div className="Article-markdown__Share__print">
              <button onClick={printPage}>
                <img
                  src={Imprimante}
                  alt="icône d'imprimante"
                  width={26}
                  height={25}
                />
                <span>J'imprime l'article pour le lire</span>
              </button>
            </div>
          </div>

          <ReactMarkdown
            className="Article-markdown"
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{ a: LinkRenderer }}
          >
            {item.contenu}
          </ReactMarkdown>

          <div className="Article-markdown__Published">
            <div className="Article-markdown__Published--first-row">
              <div className="Article-markdown__Published--date">
                Publié le {moment(item.publishedAt).format("DD/MM/YYYY")}
              </div>
              <div className="Article-markdown__Published--Social">
                <div className="Article-markdown__Published--Social-logo">
                  <ShareSocial currentUrl={currentUrl} />
                </div>
              </div>
            </div>
            <div className="Article-markdown__Published--Author">
              <p> Rédigé par {item.auteur}</p>
            </div>
          </div>
        </article>
        {components}
      </Layout>
    </>
  )
}

export default ArticleTemplate
